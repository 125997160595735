import { useContext, useEffect, useState } from "react";
import { useSelection } from "contexts/Schedules";
import { tTransportCardList, tTransportCard } from "types/transport";
import { MstDataContext } from "contexts/Mst";
import { Container, Button, Typography, Grid, Card } from "@mui/material";
import { useTheme, Theme } from "@mui/material/styles";
import { DragTransportCardArea } from "components/TransportCard";
import { Box, display } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getDrivers } from "functions/mst/users";
import { tUser } from "types/mst";
import Modal from "atoms/Modal";
import { TransportCardBlank } from "components/TransportCard";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DirtyLens } from "@mui/icons-material";
import { transportColumns } from "const/project/table";
import { tProject } from "types/project";

const ddType = "TransportCard";

export default function Main() {
  const theme = useTheme();
  const { outbound, inbound } = useSelection(); // 輸送情報を取得

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            flexGrow: 1, // 残りの高さを占める
            transition: "height 0.3s ease",
            overflow: "auto", // 高さが足りない時にスクロールバーを表示
          }}
        >
          <TransportArea theme={theme} />
        </Box>
        {/* Bのエリア: クリックで高さが0 -> 100pxに変わる */}
        <Container
          sx={{
            height: "auto",
            backgroundColor: theme.palette.common.white,
            transition: "height 0.3s ease",
            overflowX: "hidden",
            width: "100%",
          }}
        >
          <DragDriverArea />
        </Container>
      </Box>
    </DndProvider>
  );
}

function TransportArea({ theme }: { theme: Theme }) {
  const { outbound, inbound } = useSelection();
  const { users, roles } = useContext(MstDataContext);
  const [drivers, setDrivers] = useState<tUser[]>();
  const [openModal, setOpenModal] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState<tUser>();

  const [operations, setOperations] = useState<{
    [key: number]: tTransportCard;
  }>({});

  const handleDrop = (transport: tTransportCard, item: tUser) => {
    console.log("handleDrop", item);

    // 運行指示入力モーダルを表示
    setOpenModal(true);
    //setSelectedDriver(driver);
  };

  const handleDoubleClick = (transport: tTransportCard) => {
    // 運行指示入力モーダルを表示
    setOpenModal(true);
    //setSelectedDriver(driver);
  };

  useEffect(() => {
    if (!openModal) {
      setSelectedDriver(undefined);
    }
  }, [openModal]);

  useEffect(() => {
    if (users && roles) {
      setDrivers(getDrivers(users, roles));
    }
  }, [users, roles]);

  console.log("drivers", drivers);

  const TransportTable = ({ transports }: { transports: tTransportCard[] }) => {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "150px" }}>運転手</TableCell>
              <TableCell>運行指示</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transports?.map((transport: tTransportCard) => {
              return (
                <TransportRow
                  tranposrt={transport}
                  callbackDrop={handleDrop}
                  callbackDoubleClick={handleDoubleClick}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box>
      <Modal
        title={`運行指示：${selectedDriver?.family_name}${selectedDriver?.given_name}`}
        content={""}
        open={openModal}
        actions={
          <>
            <Button onClick={() => setOpenModal(false)}>閉じる</Button>
          </>
        }
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography>行き</Typography>
          <TransportTable transports={outbound} />
        </Grid>
        <Grid item xs={6}>
          <Typography>帰り</Typography>
          <TransportTable transports={inbound} />
        </Grid>
      </Grid>
    </Box>
  );
}

function TransportRow({
  tranposrt,
  callbackDrop,
  callbackDoubleClick,
}: {
  tranposrt: tTransportCard;
  callbackDrop: (tranposrt: tTransportCard, item: tUser) => void;
  callbackDoubleClick: (tranposrt: tTransportCard) => void;
}) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ddType,
    drop: (item: tUser) => {
      callbackDrop(tranposrt, item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <TableRow>
      <TableCell>{`${tranposrt.load_name} - ${tranposrt.unload_name}`}</TableCell>

      <TableCell>
        <TransportCardBlank
          ref={drop}
          flgOmit={false}
          label="新"
          callbackDoubleClick={(event) => {
            callbackDoubleClick(tranposrt);
          }}
          callbackClick={(event) => {}}
          isOver={isOver}
        />
      </TableCell>
    </TableRow>
  );
}

function DragDriverArea() {
  const theme = useTheme();
  const { users, roles, loading } = useContext(MstDataContext);
  const [drivers, setDrivers] = useState<tUser[]>();

  useEffect(() => {
    if (users && roles) {
      setDrivers(getDrivers(users, roles));
    }
  }, [users, roles]);

  if (loading) {
    return (
      <Box>
        <Typography variant="h3">Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography>運転手</Typography>
      <Box sx={{ display: "flex", overflowX: "auto" }}>
        <Box
          sx={{
            display: "inline-flex",
            gap: theme.spacing(2),
          }}
        >
          {drivers?.map((driver: tUser) => {
            return <Driver driver={driver} key={`driver-card-${driver.id}`} />;
          })}
        </Box>
      </Box>
    </Box>
  );
}

const Driver = ({ driver }: { driver: tUser }) => {
  /**
   * D&D用のフック
   */
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ddType,
    item: driver,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Card
      key={`driver-card-${driver.id}`}
      className="transport-card"
      ref={drag}
    >
      <Typography>{`${driver.family_name} ${driver.given_name}`}</Typography>
    </Card>
  );
};
