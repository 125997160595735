import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { tCompany } from "types/mst";
import { tProject } from "types/project";
import { Grid, Box, Typography } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { PrimaryButton, SecondButton } from "atoms/Button";
import PageNation, { handlePageChange } from "components/Pagenation";
import { getProjects } from "functions/api/project";
import {
  projectStatusDelivered,
  projectStatusOrdered,
} from "const/project/index";
import { getTorisakiName, getLoadName, getUnloadName } from "functions/project";
import { strDateOrigin } from "functions/time";
import { TableCellTextField } from "atoms/TextField";
import { useTheme } from "@mui/material/styles";
import { postSahimodoshi } from "functions/api/paperwork";
import { text } from "stream/consumers";
import { updateProjectStatus } from "functions/api/project";
import { projectStatusChecked } from "const/project/index";
import { checkClosingProject } from "functions/peaperWork";
import { tErrMsg, ErrMsgBox } from "components/ErrorMessage";
import Modal from "atoms/Modal";

export default function Main() {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<tProject[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState({});
  const [sahimodoshi, setSashimodoshi] = useState<{ [key: number]: string }>(
    []
  );

  const [errMsg, setErrMsg] = useState<tErrMsg>({});

  const theme = useTheme();

  // データ取得処理
  const getSearch = async (page: number = 0) => {
    getProjects(
      page,
      {
        status: [projectStatusDelivered.id, projectStatusOrdered.id],
      },
      [
        {
          field: "load_date",
          direction: "asc",
        },
        {
          field: "c_id",
          direction: "asc",
        },
        {
          field: "unload_date",
          direction: "asc",
        },
      ]
    ).then((res: any) => {
      setProjects(res.data.data);
      setCurrentPage(res.data.current_page);
      setLastPage(res.data.last_page);
      setTotalPages(res.data.last_page);
    });
  };

  /**
   * 一覧から案件を削除
   * @param pj_id
   */
  const removeProject = (pj_id: number) => {
    const projectRow = document.querySelector(`.project-row-${pj_id}`);
    if (projectRow) {
      projectRow.classList.add("removing");
    }

    setTimeout(() => {
      setProjects((prev) => {
        return prev?.filter((project) => project.id !== pj_id);
      });
    }, 500);
  };

  const handleSashimodoshi = (pj_id: number, in_charge: number) => {
    console.log(pj_id, sahimodoshi[pj_id]);

    const filter = {
      pj_id: pj_id,
      pj_in_charge: in_charge,
      text: sahimodoshi[pj_id],
    };

    // 差戻し処理
    postSahimodoshi(filter)
      .then((res) => {
        console.log(res);
        //setProjectsから該当のデータを削除
        removeProject(pj_id);
      })
      .catch((err) => {
        alert("差戻し処理に失敗しました");
      });
  };

  /**
   * ステータス更新
   * @param pj_id
   */
  const handleChecked = (pj_id: number) => {
    if (!projects) {
      return;
    }
    const project: tProject | undefined = projects.find(
      (project) => project.id === pj_id
    );
    if (!project) {
      return;
    }

    const errs: tErrMsg = checkClosingProject(project);

    console.log(errs);
    if (Object.keys(errs).length > 0) {
      setErrMsg(errs);
      return;
    } else {
      setErrMsg({});
    }

    updateProjectStatus(pj_id, projectStatusChecked.id)
      .then((res) => {
        if (res.status) console.log(res);
        removeProject(pj_id);
      })
      .catch((err) => {
        alert("確認処理に失敗しました");
      });
  };

  /**
   * 飛ばす
   * @param pj_id
   */
  const handleOmited = (pj_id: number) => {
    removeProject(pj_id);
  };

  useEffect(() => {
    getSearch(currentPage);
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={4} md={4} lg={2}>
            <SecondButton
              onClick={() => {
                getSearch(1);
              }}
              label="検索"
            />
          </Grid>
          <Grid item xs={4} md={4} lg={2}>
            <PrimaryButton
              onClick={() => {
                navigate("/mst/company/input");
              }}
              label="新規"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Modal
          open={Object.keys(errMsg).length > 0}
          title="エラー"
          content={
            <Box sx={{ padding: 2 }}>
              <ErrMsgBox errMsg={errMsg} />
            </Box>
          }
          actions={
            <SecondButton
              label="閉じる"
              onClick={() => {
                setErrMsg({});
              }}
            />
          }
        />

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>取引先</TableCell>
                <TableCell>積地</TableCell>
                <TableCell>卸地</TableCell>
                <TableCell sx={{ minWidth: "250px" }}>差戻し</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects?.map((project) => {
                return (
                  <TableRow
                    key={`projects-checkWaiting-${project.id}`}
                    className={`project-row-${project.id}`}
                    onDoubleClick={() =>
                      navigate(`/project/edit/${project.id}`)
                    }
                  >
                    <TableCell>
                      <Typography>{project.id}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{`${getTorisakiName(project)}(${
                        project.c_id
                      })`}</Typography>
                    </TableCell>
                    <TableCell>
                      <ul>
                        <li>{strDateOrigin(project.load_date)}</li>
                        <li>{getLoadName(project)}</li>
                      </ul>
                    </TableCell>
                    <TableCell>
                      <ul>
                        <li>{strDateOrigin(project.unload_date)}</li>
                        <li>{getUnloadName(project)}</li>
                      </ul>
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      <TableCellTextField
                        props={{
                          name: `sashimodoshi-${project.id}`,
                          multiline: true,
                          rows: 2,
                          onChange: (
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setSashimodoshi((prev) => ({
                              ...prev,
                              [project.id]: e.target.value,
                            }));
                          },
                        }}
                      />
                      <SecondButton
                        onClick={() =>
                          handleSashimodoshi(project.id, project.in_charge)
                        }
                        label="差戻し"
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          flexFlow: "row nowrap",
                          gap: theme.spacing(2),
                        }}
                      >
                        <PrimaryButton
                          onClick={() => handleChecked(project.id)}
                          label="確認"
                        />
                        <SecondButton
                          onClick={() => handleOmited(project.id)}
                          label="飛ばす"
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <PageNation
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
            getSearch(value)
          }
        />
      </Grid>
    </Grid>
  );
}
