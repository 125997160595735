import React, { useState, useRef, useEffect, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  Popover,
  Typography,
  Button,
  Grid,
  Container,
} from "@mui/material";
import { useSelection } from "contexts/Schedules";
import { truncateString } from "functions/index";
import { isSameDate } from "functions/time";
import { tTransportCard, tTransportCardList } from "types/transport";
import { MailOutlineSharp } from "@mui/icons-material";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

/**
 * 輸送情報カード
 * @param param0
 * @returns
 */
const TransportCard = forwardRef<
  HTMLDivElement,
  {
    selected?: boolean;
    flgOmit?: boolean;
    callbackDoubleClick: (event: React.MouseEvent<HTMLElement>) => void;
    callbackClick: (event: React.MouseEvent<HTMLElement>) => void;
    ref?: any;
    children: React.ReactNode;
    className?: string;
    isOver?: boolean;
  }
>((props, ref) => {
  const {
    selected,
    flgOmit,
    callbackDoubleClick,
    callbackClick,
    children,
    className,
    isOver = false,
  } = props;
  const clickTimeout = useRef<number | undefined>(undefined); // タイムアウトの管理
  const DOUBLE_CLICK_DELAY = 200; // ダブルクリックとシングルクリックを区別するための遅延時間

  /**
   * クリック時の処理
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    console.log("click");
    const target = event.currentTarget;
    clickTimeout.current = window.setTimeout(() => {
      callbackClick({ ...event, currentTarget: target });
    }, DOUBLE_CLICK_DELAY);
  };

  const handleDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    console.log("double click");
    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current); // clickイベントをキャンセル
    }
    callbackDoubleClick(event);
  };

  return (
    <Card
      ref={ref}
      className={`transport-card
            ${selected ? "selected" : ""} 
            ${flgOmit ? "omit" : ""} 
            ${className || ""}
            ${isOver && "isover"}
        `}
      onDoubleClick={handleDoubleClick}
      onClick={handleClick}
    >
      {children}
    </Card>
  );
});

const Toolchip = ({
  id,
  open,
  anchorEl,
  handleClose,
  children,
}: {
  id: string;
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  children?: React.ReactNode;
}) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom", // カードの下に表示
        horizontal: "center", // カードの中央に表示
      }}
      transformOrigin={{
        vertical: "top", // メニューの上側を基準に
        horizontal: "center", // メニューの中央を基準に
      }}
    >
      <Box sx={{ padding: 2 }}>{children}</Box>
    </Popover>
  );
};

/**
 * 輸送情報がリストに含まれているか確認
 * @returns
 */
const checkInTransportCard = (
  list: tTransportCardList,
  target: tTransportCard
) => {
  return list.some((card) => card.id === target.id);
};

interface MainProps {
  type: "nomal" | "transport-selectOperation" | "selected";
  transportCard: tTransportCard;
  flgOmit: boolean;
  callbackClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export default function Main({
  type,
  transportCard,
  flgOmit = true,
  callbackClick = () => {},
}: MainProps) {
  const navigate = useNavigate();

  const movePageProject = () => {
    navigate(`/project/edit/${transportCard.pj_id}`);
  };

  if (type === "nomal") {
    return (
      <TransportCardNomal
        transportCard={transportCard}
        flgOmit={flgOmit}
        callbackClick={callbackClick}
      ></TransportCardNomal>
    );
  }

  return (
    <TransportCardToInstruction
      transportCard={transportCard}
      flgOmit={flgOmit}
    />
  );
}

/**
 * 運行指示作成用輸送情報カード
 * @param param0
 * @returns
 */
export const TransportCardNomal = forwardRef<
  HTMLDivElement,
  {
    transportCard: tTransportCard;
    callbackClick: (event: React.MouseEvent<HTMLElement>) => void;
    flgOmit?: any;
    ref?: any;
    children?: React.ReactNode;
  }
>((props, ref) => {
  const { transportCard, callbackClick, flgOmit = true, children } = props;
  const navigate = useNavigate();

  const handleCardDoubleClick = () => {
    if (flgOmit) {
      return;
    }
    navigate(`/project/edit/${transportCard.pj_id}`);
  };

  const eveningLoad = isSameDate(
    transportCard.load_date,
    transportCard.unload_date
  );
  return (
    <>
      <TransportCard
        ref={ref}
        flgOmit={flgOmit}
        callbackDoubleClick={handleCardDoubleClick}
        callbackClick={callbackClick}
      >
        {children}
        {!flgOmit ? (
          <>
            <Typography>
              {truncateString(transportCard.company_name, 6)}
            </Typography>
            <Typography>
              <li>{truncateString(transportCard.load_name, 6)}</li>
              <li>{truncateString(transportCard.unload_name, 6)}</li>
            </Typography>
          </>
        ) : (
          ""
        )}
        {!flgOmit && (
          <Box className="attribute-chip">
            {eveningLoad && (
              <Typography className="chip evening">宵</Typography>
            )}
          </Box>
        )}
      </TransportCard>
    </>
  );
});

/**
 * 輸送情報カード(空)
 * @param param0
 * @returns
 */
export const TransportCardBlank = forwardRef<
  HTMLDivElement,
  {
    label: string;
    callbackDoubleClick: (event: React.MouseEvent<HTMLElement>) => void;
    callbackClick: (event: React.MouseEvent<HTMLElement>) => void;
    flgOmit?: any;
    ref?: any;
    isOver: boolean;
  }
>((props, ref) => {
  const {
    label,
    callbackDoubleClick,
    callbackClick,
    flgOmit = true,
    isOver = false,
  } = props;

  const handleCardDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    callbackDoubleClick(event);
  };

  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    callbackClick(event);
  };

  return (
    <Box className={`TransportCardBlank`}>
      <TransportCard
        ref={ref}
        flgOmit={flgOmit}
        callbackDoubleClick={handleCardDoubleClick}
        callbackClick={handleCardClick}
        className={"blank"}
        isOver={isOver}
      >
        {!flgOmit && <Typography className="label">{`${label}`}</Typography>}
      </TransportCard>
    </Box>
  );
});

/**
 * 運行指示作成用輸送情報カード
 * @param param0
 * @returns
 */
export const TransportCardToInstruction = ({
  transportCard, // 輸送情報
  flgOmit = true, // 略称表示かどうか
  switchCtrl = true, // 選択機能の有無
  flgAttributeLabel = true, // 属性ラベルの表示
}: {
  transportCard: tTransportCard;
  flgOmit?: any;
  switchCtrl?: boolean;
  flgAttributeLabel?: boolean;
}) => {
  const { outbound, inbound } = useSelection();
  const { addSelection, removeSelection } = useSelection(); // グローバル状態の関数

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // ポップオーバーのアンカー
  const [selected, setSelected] = useState(false);

  /**
   * クリック時の処理
   * @param event
   */
  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    // event.currentTargetを変数に保存する
    const targetElement = event.currentTarget;

    console.log("click", event);
    setAnchorEl(targetElement); // クリックした要素をアンカーに設定
  };

  const handleToolchipClose = () => {
    setAnchorEl(null); // ポップオーバーを閉じる
  };

  /**
   * 選択用ツールチップ処理
   * @param type
   */
  const handleToolchipSelectON = (type: string) => {
    addSelection({ transportCard, type }); // グローバル状態に選択を追加
    handleToolchipClose(); // 選択後にメニューを閉じる
  };

  /**
   * 解除用ツールチップ処理
   * @param type
   */
  const handleToolchipSelectOFF = (
    flgInbound: boolean,
    flgOutbound: boolean
  ) => {
    if (flgInbound) {
      removeSelection({ transportCard, type: "inbound" });
    }
    if (flgOutbound) {
      removeSelection({ transportCard, type: "outbound" });
    }
    handleToolchipClose(); // 選択後にメニューを閉じる
  };

  const checkSelectedInbound = () => {
    return checkInTransportCard(inbound, transportCard);
  };
  const checkSelectedOutbound = () => {
    return checkInTransportCard(outbound, transportCard);
  };

  useEffect(() => {
    setSelected(checkSelectedInbound() || checkSelectedOutbound());
  }, [inbound, outbound]);

  const open = Boolean(anchorEl);

  return (
    <Box className={`TransportCardToInstruction`}>
      <TransportCardNomal
        transportCard={transportCard}
        flgOmit={flgOmit}
        callbackClick={handleCardClick}
      >
        {flgAttributeLabel && (
          <>
            {checkSelectedInbound() && (
              <Typography className="label outbound">{`行`}</Typography>
            )}
            {checkSelectedOutbound() && (
              <Typography className="label inbound">{`帰`}</Typography>
            )}
          </>
        )}
      </TransportCardNomal>
      <Toolchip
        id={`${transportCard.pj_id}-${transportCard.no}`}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleToolchipClose}
      >
        {!selected ? (
          <>
            <Typography variant="subtitle1">選択してください</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleToolchipSelectON("outbound")}
              sx={{ marginRight: 1, marginTop: 1 }}
            >
              行き
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleToolchipSelectON("inbound")}
              sx={{ marginTop: 1 }}
            >
              帰り
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                handleToolchipSelectOFF(
                  checkSelectedInbound(),
                  checkSelectedOutbound()
                )
              }
              sx={{ marginRight: 1, marginTop: 1 }}
            >
              解除
            </Button>
          </>
        )}
      </Toolchip>
    </Box>
  );
};

/**
 * 運行指示作成用輸送情報カード
 * @param param0
 * @returns
 */
export const TransportCardToDragDrop = ({
  ddType,
  transportCard, // 輸送情報
  callbackDoubleClick, // ダブルクリック時の処理
  flgOmit = true, // 略称表示かどうか
  switchCtrl = true, // 選択機能の有無
  flgAttributeLabel = true, // 属性ラベルの表示
}: {
  ddType: string;
  transportCard: tTransportCard;
  callbackDoubleClick: () => void;
  flgOmit?: any;
  switchCtrl?: boolean;
  flgAttributeLabel?: boolean;
}) => {
  const { outbound, inbound } = useSelection();
  const { addSelection, removeSelection } = useSelection(); // グローバル状態の関数

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // ポップオーバーのアンカー
  const [selected, setSelected] = useState(false);

  /**
   * D&D用のフック
   */
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ddType,
    item: transportCard,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  /**
   * クリック時の処理
   * @param event
   */
  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    // event.currentTargetを変数に保存する
    const targetElement = event.currentTarget;

    console.log("click", event);
    setAnchorEl(targetElement); // クリックした要素をアンカーに設定
  };

  const handleToolchipClose = () => {
    setAnchorEl(null); // ポップオーバーを閉じる
  };

  /**
   * 解除用ツールチップ処理
   * @param type
   */
  const handleToolchipSelectOFF = (
    flgInbound: boolean,
    flgOutbound: boolean
  ) => {
    if (flgInbound) {
      removeSelection({ transportCard, type: "inbound" });
    }
    if (flgOutbound) {
      removeSelection({ transportCard, type: "outbound" });
    }
    handleToolchipClose(); // 選択後にメニューを閉じる
  };

  const checkSelectedInbound = () => {
    return checkInTransportCard(inbound, transportCard);
  };
  const checkSelectedOutbound = () => {
    return checkInTransportCard(outbound, transportCard);
  };

  useEffect(() => {
    setSelected(checkSelectedInbound() || checkSelectedOutbound());
  }, [inbound, outbound]);

  useEffect(() => {
    if (anchorEl === null) {
      console.log("Popover closed, anchorEl is null");
    } else {
      console.log("Popover open, anchorEl:", anchorEl);
    }
  }, [anchorEl]);

  console.log("anchorEl", anchorEl);
  const open = Boolean(anchorEl);

  return (
    <>
      <TransportCardNomal
        ref={drag}
        transportCard={transportCard}
        flgOmit={flgOmit}
        callbackClick={handleCardClick}
      />
      <Toolchip
        id={`${transportCard.pj_id}-${transportCard.no}`}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleToolchipClose}
      >
        {selected && (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                handleToolchipSelectOFF(
                  checkSelectedInbound(),
                  checkSelectedOutbound()
                )
              }
              sx={{ marginRight: 1, marginTop: 1 }}
            >
              解除
            </Button>
          </>
        )}
      </Toolchip>
    </>
  );
};

/**
 * 配車手配エリア
 * @param param0
 * @returns
 */
export const InstructionTargetArea = ({
  outbound,
  inbound,
  optionNode,
}: {
  outbound: tTransportCardList;
  inbound: tTransportCardList;
  optionNode: React.ReactNode;
}) => {
  return (
    <Container>
      {!(outbound.length === 0 && inbound.length === 0) && (
        <Grid container>
          <Grid item xs={5.5}>
            <Typography>行き</Typography>
            <Box sx={{ display: "flex", flexFlow: "wrap" }}>
              {outbound.map((transportCard, index) => {
                return (
                  <TransportCardToInstruction
                    key={`TransportCard-InstructionTargetArea-outbound-${index}`}
                    transportCard={transportCard}
                    flgOmit={false}
                    flgAttributeLabel={false}
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={5.5}>
            <Typography>帰り</Typography>
            <Box sx={{ display: "flex", flexFlow: "wrap" }}>
              {inbound.map((transportCard, index) => {
                return (
                  <TransportCardToInstruction
                    key={`TransportCard-InstructionTargetArea-inbound-${index}`}
                    transportCard={transportCard}
                    flgOmit={false}
                    flgAttributeLabel={false}
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={1}>
            {optionNode}
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

/**
 * D&Dができる運送情報カード
 * @param param0
 * @returns
 */
export const DragTransportCardArea = ({
  ddType,
  outbound,
  inbound,
  optionNode,
}: {
  ddType: string;
  outbound: tTransportCardList;
  inbound: tTransportCardList;
  optionNode: React.ReactNode;
}) => {
  return (
    <Container>
      {!(outbound.length === 0 && inbound.length === 0) && (
        <Grid container>
          <Grid item xs={5.5}>
            <Typography>行き</Typography>
            <Box sx={{ display: "flex", flexFlow: "wrap" }}>
              {outbound.map((transportCard, index) => {
                return (
                  <TransportCardToDragDrop
                    ddType={ddType}
                    key={`TransportCard-DragTransportCardArea-outbound-${index}`}
                    transportCard={transportCard}
                    flgOmit={false}
                    callbackDoubleClick={() => {}}
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={5.5}>
            <Typography>帰り</Typography>
            <Box sx={{ display: "flex", flexFlow: "wrap" }}>
              {inbound.map((transportCard, index) => {
                return (
                  <TransportCardToDragDrop
                    ddType={ddType}
                    key={`TransportCard-DragTransportCardArea-inbound-${index}`}
                    transportCard={transportCard}
                    flgOmit={false}
                    callbackDoubleClick={() => {}}
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={1}>
            {optionNode}
          </Grid>
        </Grid>
      )}
    </Container>
  );
};
