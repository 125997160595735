import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button, Grid, Paper } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { expensesColumns } from "const/project/table";
import { TableCellTextField } from "atoms/TextField";
import ModalMain from "atoms/Modal";
import * as PComponent from "components/project/Index";
import * as PWComponent from "components/project/Waypoint";
import { typeHash, typeIndexTable } from "types";
import {
  tProject,
  createEmptyProject,
  tWaypoints,
  initialWaypoint,
  tWaypoint,
  tPriceOther,
} from "types/project";
import { ButtonContainer } from "atoms/Button";
import {
  updateProject,
  checkUpdateProject,
  getProject,
  setProject,
  deleteProject,
} from "functions/api/project";
import { tErrMsg, ErrMsgBox } from "components/ErrorMessage";
import { validationCheck } from "functions/project";
import { PrimaryButton } from "atoms/Button";
import { projectStatus, projectKbn } from "const/project/index";

export default function Main() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState<tErrMsg>({});

  const defKind = Number(searchParams.get("kind")) ?? 0;
  const tempProjectInfo = {
    ...createEmptyProject(),
    kind: defKind,
  };
  const [projectInfo, setProjectInfo] = useState<tProject>(tempProjectInfo);

  const defDate = searchParams.get("date") ?? new Date().toISOString();
  // 積卸地
  let initialWaypoints = [
    { ...initialWaypoint, datetime: defDate },
    { ...initialWaypoint, kbn: projectKbn.unload.id, datetime: defDate },
  ] as tWaypoints;
  const [waypoints, setWaypoints] = useState<tWaypoints>(initialWaypoints);

  // その他金額
  const [priceOther, setPriceOther] = useState<tPriceOther[]>([]);

  // 経費テーブル
  const [expenseInfo, setExpenseInfo] = useState<typeIndexTable>([
    expensesColumns.reduce(
      (acc, column) => ({ ...acc, [column.field]: "" }),
      {}
    ),
  ]);

  const isEmptyObject = (obj: Object) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  /**
   * 新規登録処理
   * @param flgReuse
   * @returns
   */
  const sendPostRequestInsert = (flgReuse: boolean) => {
    let validationMsg: tErrMsg = {};
    validationMsg = validationCheck(projectInfo, waypoints);
    setErrMsg(validationMsg);
    if (!isEmptyObject(validationMsg)) {
      return;
    }

    // 積卸地の日時を設定
    projectInfo.load_date = waypoints[0].datetime;
    projectInfo.unload_date = waypoints[waypoints.length - 1].datetime;

    setProject(projectInfo, waypoints)
      .then((res) => {
        if (res.status !== 200) throw new Error("登録に失敗しました");
        alert("登録しました");
        //setProjectInfo(createEmptyProject());
        //setWaypoints(createEmptyWaypoints());
        if (flgReuse) return;
        window.location.href = "/project/input";
      })
      .catch((err) => {
        alert("登録に失敗しました");
      });
  };

  /**
   * 更新処理
   * @returns
   */
  const sendPostRequestUpdate = () => {
    let validationMsg: tErrMsg = {};
    validationMsg = validationCheck(projectInfo, waypoints);
    setErrMsg(validationMsg);
    if (!isEmptyObject(validationMsg)) {
      return;
    }

    // チェック処理
    checkUpdateProject(projectInfo, waypoints)
      .then((res) => {
        if (res.status !== 200) throw new Error("");

        try {
          const checkData = res.data;
          console.log(checkData);

          // メッセージがある場合のみ配列に追加
          const checkMsg: string[] = [];
          Object.keys(checkData).map((key) => {
            const obj = checkData[key];
            if (obj.status === false) {
              checkMsg.push(obj.message);
            }
          });

          console.log(checkMsg);

          // checkMsgが空でない場合にエラーメッセージをセット
          if (checkMsg.length > 0) {
            setErrMsg({ 更新チェック: checkMsg });
            return;
          }

          // 更新処理
          updateProject(projectInfo, waypoints)
            .then((res) => {
              if (res.status !== 200) throw new Error("登録に失敗しました");
              alert("登録しました");
              //setProjectInfo(createEmptyProject());
              //setWaypoints(createEmptyWaypoints());
            })
            .catch((err) => {
              alert("登録に失敗しました");
            });
        } catch (error) {
          console.error(error);
        }
      })
      .catch((err) => {
        alert("更新チェック処理に失敗しました");
      });
  };

  /**
   * 削除処理
   * @returns
   */
  const sendPostRequestDelete = () => {
    if (!window.confirm("削除しますか？")) return;
    deleteProject(projectInfo.id)
      .then((res: any) => {
        if (res.status !== 200) throw new Error("データ削除に失敗しました");
        alert("データを削除しました");
        window.location.href = "/project/list";
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /**
   * プロジェクト情報取得
   */
  useEffect(() => {
    const fetchData = async (id: string) => {
      try {
        await getProject(id)
          .then((res) => {
            setProjectInfo(res.data);
            setWaypoints(res.data.waypoints);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (error) {
        console.error(error);
      }
    };

    if (id) {
      fetchData(id);
    } else {
      tempProjectInfo.status = 10; // 受注済みにする
      setProjectInfo(tempProjectInfo);
      setWaypoints(initialWaypoints);
    }
  }, [id]);

  return (
    <>
      <ModalMain
        title="経費を入力する"
        open={open}
        content={
          <ModalContent
            id={"expense"}
            projectInfo={expenseInfo}
            setProjectInfo={setExpenseInfo}
          />
        }
        actions={
          <ButtonContainer>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(false)}
            >
              閉じる
            </Button>
          </ButtonContainer>
        }
      />
      {Object.keys(errMsg).length > 0 && (
        <Box sx={{ padding: 2 }}>
          <ErrMsgBox errMsg={errMsg} />
        </Box>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {id ? (
              <>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton label="更新" onClick={sendPostRequestUpdate} />
                </Grid>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton onClick={sendPostRequestDelete} label="削除" />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton
                    onClick={() => sendPostRequestInsert(false)}
                    label="登録"
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton
                    onClick={() => sendPostRequestInsert(true)}
                    label="続けて登録"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <PComponent.ClientCompany
          values={projectInfo}
          setValues={setProjectInfo}
        />
        <PComponent.Person values={projectInfo} setValues={setProjectInfo} />

        <PComponent.Status values={projectInfo} setValues={setProjectInfo} />
        <PComponent.Kind values={projectInfo} setValues={setProjectInfo} />
        <PComponent.VehicleType
          values={projectInfo}
          setValues={setProjectInfo}
        />

        {/* memo:指示書送付済みフラグを追加 */}

        <Grid item xs={12}>
          <Grid container spacing={1}>
            <PWComponent.WaypointsTable
              label="積地・卸地"
              values={waypoints}
              setValues={setWaypoints}
            />
          </Grid>
        </Grid>

        <PComponent.Weight values={projectInfo} setValues={setProjectInfo} />
        <PComponent.PriceUnit values={projectInfo} setValues={setProjectInfo} />
        <PComponent.LuggageID values={projectInfo} setValues={setProjectInfo} />
        <PComponent.InCharge values={projectInfo} setValues={setProjectInfo} />
        <Grid item xs={12}></Grid>
        <PComponent.Memo values={projectInfo} setValues={setProjectInfo} />

        <PComponent.InternalMemo
          values={projectInfo}
          setValues={setProjectInfo}
        />
      </Grid>
    </>
  );
}

// 経費を入力するモーダルのコンテンツ
interface ModalContentProps {
  id: string;
  projectInfo: typeIndexTable;
  setProjectInfo: React.Dispatch<typeIndexTable>;
}

function ModalContent({ id, projectInfo, setProjectInfo }: ModalContentProps) {
  const handleAddRow = () => {
    setProjectInfo([
      ...projectInfo,
      expensesColumns.reduce(
        (acc, column) => ({ ...acc, [column.field]: "" }),
        {}
      ),
    ]);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {expensesColumns.map((column) => (
                <TableCell key={column.field}>{column.headerName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {projectInfo.map((row: typeHash, rowIndex: number) => (
              <TableRow key={rowIndex}>
                {expensesColumns.map((column) => (
                  <TableCell key={column.field}>
                    <TableCellTextField
                      props={{
                        name: `${id}[${rowIndex}][${column.field}]`,
                        value: row[column.field],
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          projectInfo[rowIndex][column.field] = e.target.value;
                          setProjectInfo([...projectInfo]);
                        },
                      }}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        color={"secondary"}
        variant="contained"
        sx={{ mt: 2 }}
        onClick={handleAddRow}
      >
        行追加
      </Button>
    </>
  );
}
