import { projectStatus } from "const/project/index";
import { tProject, tWaypoints, tWaypoint } from "types/project";
import { List, ListItem } from "types/index";
import {
  defaultValidationRules,
  validateCheck,
  tValidationRules,
} from "functions/validationMessage";
import { tErrMsg, ErrMsgBox } from "components/ErrorMessage";

/**
 * ステータスIDからlabelを取得
 * @param id
 * @returns
 */
export function getStatusLabel(id: number) {
  const target = projectStatus.filter((status: ListItem) => {
    return status.id === id;
  });

  return target[0].label;
}

export function validationCheck(
  info: tProject,
  waypoints: tWaypoints
): tErrMsg {
  if (info.status === 0) {
    return validationStatus0(info, waypoints);
  } else if (info.status === 10) {
    return validationStatus10(info, waypoints);
  }

  return {};
}

/**
 * 取引先名取得処理
 * @param project
 * @returns
 */
export function getTorisakiName(project: tProject) {
  if (!project.company) {
    return `新規`;
  } else if (project.company.abbreviation) {
    return `${project.company.abbreviation}`;
  } else if (project.company.name) {
    return project.company.name;
  } else {
    return `新規`;
  }
}

/**
 * 積地名称
 * @param project
 * @returns
 */
export function getLoadName(project: tProject) {
  if (project.load_address?.abbreviation) {
    return `${project.load_address?.abbreviation}`;
  } else if (project.load_address?.name) {
    return project.load_address.name;
  } else {
    return `${project.load_prefectures}${project.load_city}`;
  }
}

/**
 * 卸地点名称
 * @param project
 * @returns
 */
export function getUnloadName(project: tProject) {
  if (project.unload_address?.abbreviation) {
    return `${project.unload_address?.abbreviation}`;
  } else if (project.unload_address?.name) {
    return project.unload_address.name;
  } else {
    return `${project.unload_prefectures}${project.unload_city}`;
  }
}

/**
 * バリデーションチェック
 * ステータス：見積もり中
 */
function validationStatus0(info: tProject, waypoints: tWaypoints): tErrMsg {
  let results: tErrMsg = {}; // 型を定義して初期化

  // infoのステータスが0か確認
  if (info.status !== 0) {
    results.status.push(
      "システムエラー：ステータスとバリデーションチェックが一致していません"
    );

    return results;
  }

  /**
   * 積卸地 バリデーションルール
   * */
  let wpRorles = getValidateWaypointRoles();
  wpRorles.datetime.dateFuture = true; // 見積時は過去の日付を許可しない
  wpRorles.city.required = false;
  wpRorles.street.required = false;
  wpRorles.building.required = false;
  //validateWaypoints(waypoints, wpRorles);
  results = Object.assign(results, validateWaypoints(waypoints, wpRorles));

  // 荷物数量
  let lVolumnsRoles = getValidateLVolume();
  results.l_volume = validateCheck(
    "数量",
    info.l_volume,
    "number",
    lVolumnsRoles
  );
  if (results.l_volume.length === 0) {
    delete results.l_volume;
  }

  // 車両台数
  const carsRoles = getValidateCars();
  results.cars = validateCheck("台数", info.cars, "number", carsRoles);
  if (results.cars.length === 0) {
    delete results.cars;
  }

  // 積載量
  const weightRoles = getValidateWeight();
  results.weight = validateCheck("重量", info.weight, "number", weightRoles);
  if (results.weight.length === 0) {
    delete results.weight;
  }

  // 単価
  let pUnitRoles = getValidatePUnit();
  results.price_unit = validateCheck(
    "単価",
    info.price_unit,
    "number",
    pUnitRoles
  );
  if (results.price_unit.length === 0) {
    delete results.price_unit;
  }

  // 合計金額
  const priceRoles = getValidatePrice();
  results.price = validateCheck("合計金額", info.price, "number", priceRoles);
  if (results.price.length === 0) {
    delete results.price;
  }

  return results;
}

/**
 * バリデーションチェック
 * ステータス：受注済
 */
function validationStatus10(info: tProject, waypoints: tWaypoints): tErrMsg {
  let results: tErrMsg = {}; // 型を定義して初期化

  // infoのステータスが10か確認
  if (info.status !== 10) {
    results.status.push(
      "システムエラー：ステータスとバリデーションチェックが一致していません"
    );

    return results;
  }

  // 取引先
  let cIdRoles = getValidateCId();
  results.c_id = validateCheck("取引先", info.c_id, "", cIdRoles);
  if (results.c_id.length === 0) {
    delete results.c_id;
  }

  /**
   * 積卸地 バリデーションルール
   * */
  const wpRorles = getValidateWaypointRoles();
  // 二つの配列をマージ
  results = Object.assign(results, validateWaypoints(waypoints, wpRorles));

  // 荷物数量
  let lVolumnsRoles = getValidateLVolume();
  results.l_volume = validateCheck(
    "数量",
    info.l_volume,
    "number",
    lVolumnsRoles
  );
  if (results.l_volume.length === 0) {
    delete results.l_volume;
  }

  // 車両台数
  const carsRoles = getValidateCars();
  results.cars = validateCheck("台数", info.cars, "number", carsRoles);
  if (results.cars.length === 0) {
    delete results.cars;
  }

  // 積載量
  const weightRoles = getValidateWeight();
  results.weight = validateCheck("重量", info.weight, "number", weightRoles);
  if (results.weight.length === 0) {
    delete results.weight;
  }

  // 単価
  let pUnitRoles = getValidatePUnit();
  results.price_unit = validateCheck(
    "単価",
    info.price_unit,
    "number",
    pUnitRoles
  );
  if (results.price_unit.length === 0) {
    delete results.price_unit;
  }

  // 合計金額
  const priceRoles = getValidatePrice();
  results.price = validateCheck("合計金額", info.price, "number", priceRoles);
  if (results.price.length === 0) {
    delete results.price;
  }

  return results;
}

function validateWaypoints(
  values: tWaypoints,
  roles: Record<string, tValidationRules>
) {
  let results: tErrMsg = {}; // 型を定義して初期化

  values.map((waypoint: tWaypoint, index: number) => {
    const fnInit = "積卸" + (index + 1) + " ";
    let temp: string[] = [];

    // 初回積地と判定
    temp = temp.concat(
      validateCheck(fnInit + "日時", waypoint.datetime, "date", roles.datetime)
    );

    temp = temp.concat(
      validateCheck(fnInit + "名称", waypoint.a_name, "string", roles.name)
    );

    temp = temp.concat(
      validateCheck(
        fnInit + "都道府県",
        waypoint.prefectures,
        "string",
        roles.prefectures
      )
    );

    console.log(waypoint.city);
    temp = temp.concat(
      validateCheck(fnInit + "市区町村", waypoint.city, "string", roles.city)
    );

    temp = temp.concat(
      validateCheck(fnInit + "番地", waypoint.street, "string", roles.street)
    );

    if (waypoint.building) {
      temp = temp.concat(
        validateCheck(
          fnInit + "建屋・その他",
          waypoint.building,
          "string",
          roles.building
        )
      );
    }

    if (temp.length > 0) {
      results["waypoint" + index] = temp;
    }
  });

  return results;
}

/////////////////////////////////////////////////////////////////バリデーションルール：受注済みをベースとする
/**
 * 積卸値
 * @returns
 */
function getValidateWaypointRoles() {
  let wpRorles: Record<string, tValidationRules> = {};

  let wpDatetimeRoles = { ...defaultValidationRules }; // 日時
  wpDatetimeRoles.required = true;
  wpRorles.datetime = wpDatetimeRoles;

  let wpNameRoles = { ...defaultValidationRules }; // 名称
  wpNameRoles.required = false;
  wpNameRoles.maxLength = 20;
  wpRorles.name = wpNameRoles;

  let wpPrefecturesRoles = { ...defaultValidationRules }; // 都道府県
  wpPrefecturesRoles.required = true;
  wpPrefecturesRoles.maxLength = 5;
  wpRorles.prefectures = wpPrefecturesRoles;

  let wpCityRoles = { ...defaultValidationRules }; // 市区町村
  wpCityRoles.required = true;
  wpCityRoles.maxLength = 20;
  wpRorles.city = wpCityRoles;

  let wpStreetRoles = { ...defaultValidationRules }; // 番地
  wpStreetRoles.required = false;
  wpStreetRoles.maxLength = 100;
  wpRorles.street = wpStreetRoles;

  let wpBuildingRoles = { ...defaultValidationRules }; // 建物・その他
  wpBuildingRoles.required = false;
  wpBuildingRoles.maxLength = 100;
  wpRorles.building = wpBuildingRoles;

  return wpRorles;
}

/**
 * 単価
 * @returns
 */
function getValidatePUnit() {
  let role = { ...defaultValidationRules };
  role.required = false;
  role.numberGreaterBelow = -1;
  role.numberGreaterOver = 100000000;
  return role;
}

/**
 * 車両数
 * @returns
 */
function getValidateCars() {
  let role = { ...defaultValidationRules };
  role.required = true;
  role.numberGreaterBelow = 0;
  role.numberGreaterOver = 10000;
  return role;
}

/**
 * 積載量
 * @returns
 */
function getValidateWeight() {
  let role = { ...defaultValidationRules };
  role.required = false;
  role.numberGreaterBelow = -1;
  role.numberGreaterOver = 10000000;
  return role;
}

/**
 * 合計金額
 * @returns
 */
function getValidatePrice() {
  let role = { ...defaultValidationRules };
  role.required = false;
  role.numberGreaterBelow = -1;
  role.numberGreaterOver = 100000000;
  return role;
}

/**
 * 荷物数量
 * @returns
 */
function getValidateLVolume() {
  let role = { ...defaultValidationRules };
  role.required = false;
  role.numberGreaterBelow = -1;
  role.numberGreaterOver = 10000;
  return role;
}

/**
 * 取引先
 * @returns
 */
function getValidateCId() {
  let role = { ...defaultValidationRules };
  role.required = true;
  role.numberGreaterBelow = 1;
  return role;
}

/**
 * tProject型の配列からpriceとprice_taxの合計を算出する関数
 * @param projects - tProject型の配列
 * @returns { totalPrice: number, totalPriceTax: number } - 合計金額オブジェクト
 */
export function calculateTotalPrice(projects: tProject[]): {
  totalPrice: number;
  totalPriceTax: number;
} {
  // 合計金額を初期化
  let totalPrice = 0;
  let totalPriceTax = 0;

  // 配列をループして合計金額を計算
  projects.forEach((project) => {
    totalPrice += project.price;
    totalPriceTax += project.price_tax;
  });

  return {
    totalPrice,
    totalPriceTax,
  };
}
