export const getNowOrigin = strDateTimeOrigin(dateTime(0, 0, 0, 0, 0, 0));

export function getNow() {
  let obj = dateTime(0, 0, 0, 0, 0, 0);
  return strDateTime(obj);
}
export function getAddOneDay() {
  let obj = dateTime(0, 0, 1, 0, 0, 0);
  return strDateTime(obj);
}
export function getAddOneHour() {
  let obj = dateTime(0, 0, 0, 1, 0, 0);
  return strDateTime(obj);
}
export function getAddOneMin() {
  let obj = dateTime(0, 0, 0, 0, 1, 0);
  return strDateTime(obj);
}

/**
 * Date型をinput type="date"に変換する
 * @param str
 * @returns
 */
export function changeFromDatetimeToInputTypeDate(str: Date) {
  try {
    return new Date(str).toISOString().split("T")[0];
  } catch (error) {
    return "";
  }
}

// 日付をフォーマット（yyyy-mm-ddTHH:mm）する関数
export const changeFromDatetimeToInputTypeDatetime = (date: Date): string => {
  const pad = (num: number) => (num < 10 ? "0" + num : num);
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

/**
 * 日付をフォーマット（yyyy/mm/dd）する関数
 * @param objData
 * @returns string  "yyyy-MM-dd hh:mm"
 */
export function strDateTimeOrigin(objData: Date) {
  // objDataが文字列の場合、Dateオブジェクトに変換する
  const date = typeof objData === "string" ? new Date(objData) : objData;

  // Dateオブジェクトであることを確認する
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new Error("Invalid date");
  }

  let strYear = String(date.getFullYear());
  let strMonth = String(date.getMonth() + 1).padStart(2, "0"); // 月を1足して2桁にする
  let strDate = String(date.getDate()).padStart(2, "0");
  let strHour = String(date.getHours()).padStart(2, "0");
  let strMin = String(date.getMinutes()).padStart(2, "0");
  //let strSec = String(date.getSeconds()).padStart(2, '0');

  let result = "";
  result = result.concat(strYear + "/");
  result = result.concat(strMonth + "/");
  result = result.concat(strDate + " ");
  result = result.concat(strHour + ":");
  result = result.concat(strMin);
  //result = result.concat(strSec);

  return result;
}

/**
 * 日付をフォーマット（yyyy/mm/dd）する関数
 * @param strDatetime
 * @returns string  "yyyy-MM-dd hh:mm"
 */
export function strDateOrigin(strDatetime: string) {
  const obj = new Date(strDatetime);
  const datetime = strDateTimeOrigin(obj);
  return datetime.split(" ")[0];
}

/**
 *
 * @param objData
 * @param separate
 * @returns string
 */
function strDateTime(objData: Date, separate: string = "") {
  let strYear = String(objData.getFullYear());
  let strMonth = String(objData.getMonth());
  let strDate = String(objData.getDate());
  let strHour = String(objData.getHours());
  let strMin = String(objData.getMinutes());
  let strSec = String(objData.getSeconds());

  let result = "";
  result = result.concat(strYear + separate);
  result = result.concat(strMonth + separate);
  result = result.concat(strDate + separate);
  result = result.concat(strHour + separate);
  result = result.concat(strMin + separate);
  result = result.concat(strSec + separate);

  return result;
}

function dateTime(
  addYear: number,
  addMonth: number,
  addDay: number,
  addHour: number,
  addMin: number,
  addSec: number
) {
  let date = new Date();

  date.setFullYear(date.getFullYear() + addYear);
  date.setMonth(date.getMonth() + addMonth);
  date.setDate(date.getDate() + addDay);
  date.setHours(date.getHours() + addHour);
  date.setMinutes(date.getMinutes() + addMin);
  date.setSeconds(date.getSeconds() + addSec);

  return date;
}

/**
 * 日付計算
 * @param objData
 * @param ary
 * @returns Date
 */
export function MathDateTime(objData: Date, ary: number[]) {
  let strYear = objData.getFullYear() + ary[0];
  let strMonth = objData.getMonth() + ary[1];
  let strDate = objData.getDate() + ary[2];
  let strHour = objData.getHours() + ary[3];
  let strMin = objData.getMinutes() + ary[4];
  let strSec = objData.getSeconds() + ary[5];

  return new Date(strYear, strMonth, strDate, strHour, strMin, strSec);
}

/**
 * 日時を比較して同じ日付ならtrueを返す
 * @param datetime1
 * @param datetime2
 * @returns
 */
export const isSameDate = (datetime1: string, datetime2: string) => {
  const date1 = new Date(datetime1);
  const date2 = new Date(datetime2);

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

/**
 * 引数の日付の直前の月曜日の日付を取得する
 * @param date
 * @returns
 */
export function getPreviousMonday(date: string) {
  const currentDate = new Date(date);
  const day = currentDate.getDay();
  const diff = day === 0 ? 6 : day - 1;
  currentDate.setDate(currentDate.getDate() - diff);
  return currentDate;
}

/**
 * 引数の日付の直前の月曜日の日付を取得する
 * @param date
 * @returns
 */
export function getCharWeekday(date: string) {
  const currentDate = new Date(date);
  // 曜日を取得
  const weekday = currentDate.getDay();
  // 曜日を文字列に変換
  const charWeekday = ["日", "月", "火", "水", "木", "金", "土"][weekday];
  return charWeekday;
}

/**
 * 受け取った日付の今月の日付を取得
 * @param day
 * @returns
 */
export function getSameDayThisMonth(day: number) {
  const now = new Date(); // 現在の日付
  const currentYear = now.getFullYear(); // 現在の年
  const currentMonth = now.getMonth(); // 現在の月 (0-11)

  // 指定された日で日付を作成
  const targetDate = new Date(currentYear, currentMonth, day);

  // 日付が同じかどうかを確認して、存在しない日が指定された場合はその月の最終日を返す
  if (targetDate.getMonth() !== currentMonth) {
    // 次の月の0日目でその月の最終日を取得
    return new Date(currentYear, currentMonth + 1, 0);
  }

  return targetDate;
}
/**
 * 受け取った日付の直前月の日付を取得
 * @param day
 * @returns
 */
export function getSameDayPreviousMonth(
  day: number,
  target: Date = new Date()
) {
  const currentYear = target.getFullYear(); // 現在の年
  const currentMonth = target.getMonth(); // 現在の月 (0-11)

  // 直前の月の日付を作成
  const previousMonthDate = new Date(currentYear, currentMonth - 1, day);

  // 日付がその月に存在しない場合は、その月の最終日を返す
  if (previousMonthDate.getMonth() !== (currentMonth - 1 + 12) % 12) {
    // 直前の月の0日目でその月の最終日を取得
    return new Date(currentYear, currentMonth, 0);
  }

  return previousMonthDate;
}

/**
 * 指定の日付から「YYYY年M月」の形式に変換
 * @param date - 対象の日付
 * @returns 「YYYY年M月」の形式の文字列
 */
export function formatYearMonth(date: Date): string {
  const year = date.getFullYear(); // 年を取得
  const month = date.getMonth() + 1; // 月を取得（0-11なので+1）

  return `${year}年${month}月`;
}

/**
 * 指定の日付から「M月D日」の形式に変換
 * @param date - 対象の日付
 * @returns 「M月D日」の形式の文字列
 */
export function formatMonthDay(date: Date): string {
  const month = date.getMonth() + 1; // 月を取得（0-11なので+1）
  const day = date.getDate(); // 日を取得

  return `${month}月${day}日`;
}
