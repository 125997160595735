import { tUser, tRole } from "types/mst";

export function getDrivers(users: tUser[], roles: tRole[]) {
  // 案件入力を許可しているロールを取得
  const roleDriver = roles.filter((role) => role.driver === true);

  // user.role_idがprojectRolesに含まれているユーザーを取得
  const drivers = users.filter((user) =>
    roleDriver.map((role) => role.id).includes(user.role_id)
  );

  return drivers;
}
