import { create, API_URL } from "functions/axios";
import { tProject, tWaypoints } from "types/project";
import { typeHash } from "types";

/**
 * 予定表案件情報取得
 * @returns
 */
export function getTransportCardList($terms: any) {
  const ins = create();
  return ins.get(API_URL.base + "/transport/card-list", { params: $terms });
}
