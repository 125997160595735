import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { dark } from "@mui/material/styles/createPalette";
import deepmerge from "deepmerge";
import { BorderStyle } from "@mui/icons-material";

export const palette = {
  primary: "#ed7700",
  secondary: "#73a7d1",
  black: "#222222",
  white: "#FFFFFF",
  glay: "#f5f5f5",
  glayDark: "#a9a9a9",
};

// 全体のテーマ設定
const base = createTheme({
  palette: {
    primary: {
      main: palette.primary,
      contrastText: palette.white,
    },
    secondary: {
      main: palette.secondary,
      contrastText: palette.white,
    },
    text: {
      primary: palette.black,
      secondary: palette.white,
    },
    background: {
      default: palette.white,
    },
    common: {
      black: palette.black,
      white: palette.white,
    },
  },
  typography: {
    h1: {
      fontSize: "2.0rem",
    },
    body1: {
      fontSize: "1.0rem",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

let themeBase = createTheme(
  deepmerge(base, {
    ...base,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            whiteSpace: "normal", // 明示的に指定
          },
          main: {
            height: "calc(100vh - 40px)",
            flexGrow: 1 /* 残りの横幅を均等に分配する */,
            flexBasis: 0,
            minWidth: 0 /* 必要に応じて、文字がはみ出ないようにするために設定 */,
            overflow: "scroll",
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            height: "40px",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: palette.white,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: palette.primary,
            "&.Mui-checked": {
              color: palette.primary,
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            whiteSpace: "nowrap",
            "&.number": {
              textAlign: "right",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputLabel-root": {
              color: palette.primary,
            },
            /*
          "& .MuiInputBase-root": {
            color: color.primary,
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: color.primary,
          },
          */
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: palette.primary,
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: palette.primary,
              },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: "bold",
            whiteSpace: "nowrap",
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            "& .MuiTableCell-root": {
              border: `1px solid ${palette.black}`,
              padding: "4px",
            },
            "& .MuiTableCell-head": {
              textAlign: "center",
              backgroundColor: palette.primary,
              color: palette.white,
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&.removing": {
              transition: "height 0.5s ease, opacity 0.5s ease",
              height: "0",
              padding: "0",
              margin: "0",
              opacity: 0,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: palette.primary,
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: palette.primary,
              },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            "&.transport-card": {
              padding: "0.25rem",
              overflow: "hidden",
              margin: 4,
              height: "60px",
              width: "100px",
              "&.omit": {
                height: "30px",
                width: "40%",
              },
              "&.blank": {
                opacity: 0.8,
                boxShadow: "none",
                border: `1px dashed ${base.palette.common.black}`,
              },
              "&.isover": {
                border: `none`,
                backgroundColor: base.palette.primary.main,
                color: base.palette.common.white,
              },
              position: "relative",
              "& *": {
                fontSize: "0.6rem",
                position: "relative", // 重なり順を変えるため
                zIndex: 1,
              },
              "&.evening": {
                border: `2px solid ${base.palette.secondary.main}`,
              },
              "& .label": {
                position: "absolute",
                // 中央表示
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 0, // 一番下に表示

                // 透明にする
                opacity: 0.7,

                // 文字のサイズなど
                width: "30px",
                heiht: "30px",
                textAlign: "center",
                lineHeight: "30px",
                borderRadius: "50%",
                fontSize: "larger",
                fontWeight: "bold",

                "&.inbound": {
                  backgroundColor: `${base.palette.primary.main}`,
                  color: `${base.palette.common.white}`,
                },
                "&.outbound": {
                  backgroundColor: `${base.palette.secondary.main}`,
                  color: `${base.palette.common.white}`,
                },
              },
              "& .attribute-chip": {
                position: "absolute",
                right: 5,
                bottom: 2,
                "& > .chip": {
                  fontSize: "0.5rem",
                  display: "flex",
                  width: "13px",
                  height: "13px",
                  borderRadius: "2px",
                  alignItems: "center" /* 縦方向の中央揃え */,
                  justifyContent: "center" /* 横方向の中央揃え */,
                },
                "& .evening": {
                  backgroundColor: "rgb(168, 88, 168)",
                  color: base.palette.common.white,
                },
              },
            },
          },
        },
      },
    },
  })
);

export default themeBase;

export function ThemeProviderDark({ children }: any) {
  return (
    <ThemeProvider theme={themeBase}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export function ThemeProviderCustom({ children }: any) {
  return (
    <ThemeProvider theme={themeBase}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export const colorSub01 = {
  primary: "#a1cff4",
  secondary: "#FFFFFF",
  background: "#222222",
};
