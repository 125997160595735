import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { tUser } from "types/mst";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from "@mui/material";
import { PrimaryButton } from "atoms/Button";
import { getUsers } from "functions/api/mst";
import { MstDataContext } from "contexts/Mst";
import PageNation from "components/Pagenation";

export default function Main() {
  const navigate = useNavigate();
  const [users, setUsers] = useState<tUser[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { loading, roles } = useContext(MstDataContext);

  const getSearch = async (page: number) => {
    try {
      getUsers(page)
        .then((res) => {
          if (res.status !== 200) throw new Error("データ取得に失敗しました");
          setUsers(res.data.data);
          setCurrentPage(res.data.current_page);
          setLastPage(res.data.last_page);
          setTotalPages(res.data.last_page);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getSearch(currentPage);
  }, []);

  if (loading || !roles) {
    return <div>Loading...</div>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={4} md={4} lg={2}>
            <PrimaryButton
              onClick={() => {
                navigate("/mst/user/input");
              }}
              label="新規"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <TableContainer>
          <Table sx={{ maxWidth: "600px" }}>
            <TableHead>
              <TableRow>
                <TableCell>名前</TableCell>
                <TableCell>デフォルト車両</TableCell>
                <TableCell>権限</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                const role = roles.find((role) => role.id === user.role_id);
                return (
                  <TableRow
                    key={`users-${user.id}`}
                    onDoubleClick={() => navigate(`/mst/user/edit/${user.id}`)}
                  >
                    <TableCell>
                      {user.family_name + " " + user.given_name}
                    </TableCell>
                    <TableCell>{user.v_id}</TableCell>
                    <TableCell>{role?.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <PageNation
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
            getSearch(value)
          }
        />
      </Grid>
    </Grid>
  );
}
