// リダイレクト
import React, { useEffect, useState, useContext, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";
import { SchedulesThemeProvider } from "themes/SchedulesTheme";
import {
  Typography,
  Checkbox,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Link,
  Container,
} from "@mui/material";
import { borderColor, styled } from "@mui/system";
import { palette } from "themes/Base";
import Modal from "atoms/Modal";
import { MstDataContext } from "contexts/Mst";
import { typeHash } from "types";
import {
  isSameDate,
  MathDateTime,
  getPreviousMonday,
  getCharWeekday,
} from "functions/time";
import { initialProjectKind, tProjectKind } from "types/mst";
import { ClientCompany } from "components/project/Index";
import { RangeLoadDatetime } from "components/project/Search";
import { LinkPrimaryButton } from "atoms/Button";
import { getTransportCardList } from "functions/api/transport";
import { tTransportCardList, tTransport } from "types/transport";
import { getTorisakiName, getLoadName, getUnloadName } from "functions/project";
import { useSelection } from "contexts/Schedules";
import TransportCard, {
  InstructionTargetArea,
  TransportCardBlank,
} from "components/TransportCard";
import { tTransportSearch } from "types/transport";
import { tProjectSearch } from "types/project";

interface projectProps {
  load_date_from: string;
  load_date_to: string;
  c_id: number | null;
}

interface transportProps {
  status: number[] | null;
}

// 案件情報検索条件に積み日範囲を必須にするための型
type tProjectSearchCustom = tProjectSearch & {
  load_date_from: string;
  load_date_to: string;
};

type tTransportSearchCustom = tTransportSearch & {
  project: tProjectSearchCustom;
};

const ContentsArea = styled("div")({
  "& .head": {
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
    //border: "1px solid #e0e0e0",
    position: "sticky",
    top: 0,
    zIndex: 95,
    "& .cell": {
      textAlign: "center",
      "& .MuiFormControlLabel-root": {
        margin: "auto",
      },
      "&.day": {
        position: "sticky",
        left: 0,
        zIndex: 99,
        margin: "none",
        backgroundColor: "inherit",
      },
    },
  },
  "& .cell": {
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    "&.day": {
      width: "150px",
      //border: "1px solid #e0e0e0",
      textAlign: "center",
    },
    "&.kind": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      width: "100px",
      //border: "1px solid #e0e0e0",
      "&.active": {
        minWidth: "220px",
        borderLeft: "1px solid e0e0e0",
        borderRight: "1px solid e0e0e0",
      },
    },
  },

  "& .row": {
    display: "inline-flex",
    border: "1px solid black",
    minWidth: "100%",
  },

  "& .row.data": {
    "&.Monday": {
      ".kind.odd": { backgroundColor: "rgba(255, 211, 44, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(255, 211, 44, 0.6)" },
    },
    "&.Tuesday": {
      ".kind.odd": { backgroundColor: "rgba(240, 107, 68, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(240, 107, 68, 0.6)" },
    },
    "&.Wednesday": {
      ".kind.odd": { backgroundColor: "rgba(93, 127, 204, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(93, 127, 204, 0.6)" },
    },
    "&.Thursday": {
      ".kind.odd": { backgroundColor: "rgba(93, 170, 102, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(93, 170, 102, 0.6)" },
    },
    "&.Friday": {
      ".kind.odd": { backgroundColor: "rgba(204, 170, 51, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(204, 170, 51, 0.6)" },
    },
    "&.Saturday": {
      ".kind.odd": { backgroundColor: "rgba(139, 103, 61, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(139, 103, 61, 0.6)" },
    },
    "&.Sunday": {
      ".kind.odd": { backgroundColor: "rgba(255, 135, 63, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(255, 135, 63, 0.6)" },
    },
    "& .cell.day": {
      backgroundColor: "#f5f5f5",
      display: "flex",
      justifyItems: "center",
      alignItems: "center",
      position: "sticky",
      left: 0,
      zIndex: 91,
      "& *": {
        margin: "auto",
      },
    },
    "& .cell.kind": {
      "& .status-complete": {
        backgroundColor: palette.glayDark,
        color: palette.white,
      },
      "& .status-transport": {
        //backgroundColor: useTheme.palette.primary.main,
      },
      "& .status-no": {
        backgroundColor: palette.white,
      },
    },
  },
});

export default function Mian() {
  const theme = useTheme();
  const navigate = useNavigate();

  const { outbound, inbound } = useSelection();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (outbound.length > 0 || inbound.length > 0) {
      setIsExpanded(true);
    }
  }, [outbound, inbound]);

  return (
    <SchedulesThemeProvider>
      <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        <ContentsArea
          sx={{
            flexGrow: 1, // 残りの高さを占める
            transition: "height 0.3s ease",
            overflow: "auto", // 高さが足りない時にスクロールバーを表示
          }}
        >
          <Contents />
        </ContentsArea>
        {/* Bのエリア: クリックで高さが0 -> 100pxに変わる */}
        <Container
          sx={{
            height: isExpanded ? "auto" : "0px",
            backgroundColor: theme.palette.common.white,
            transition: "height 0.3s ease",
            //overflow: "hidden", // 高さが0の時にコンテンツが見えないように
          }}
        >
          <InstructionTargetArea
            outbound={outbound}
            inbound={inbound}
            optionNode={
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/operation/base-transport");
                }}
              >
                配車手配
              </Button>
            }
          />
        </Container>
      </Box>
    </SchedulesThemeProvider>
  );
}

function Contents() {
  const navigate = useNavigate();
  const [openSearch, setOpenSearch] = useState(false);
  const [dateTransports, setDateTransports] = useState<
    Record<string, tTransportCardList>
  >({});

  const { loading, projectKinds } = useContext(MstDataContext);
  const [viewPK, setViewPK] = useState<tProjectKind[]>([]);
  const [checkedKind, setCheckedKind] = useState<Record<number, boolean>>({});

  const [searchPJ, setSearchPJ] = useState<tProjectSearchCustom>({
    status: null,
    load_date_from: getPreviousMonday(new Date().toISOString())
      .toISOString()
      .split("T")[0],
    load_date_to: MathDateTime(new Date(), [0, 0, 7, 0, 0, 0])
      .toISOString()
      .split("T")[0],
    unload_date_from: null,
    unload_date_to: null,
    c_id: null,
  });

  // 検索処理
  const [viewDate, setViewDate] = useState<Record<string, typeHash>>({});
  const searchProject = async () => {
    const search: tTransportSearchCustom = {
      status: null,
      project: {
        status: null,
        load_date_from: searchPJ.load_date_from,
        load_date_to: searchPJ.load_date_to,
        unload_date_from: searchPJ.unload_date_from,
        unload_date_to: searchPJ.unload_date_to,
        c_id: searchPJ.c_id,
      },
    };

    const res = await getTransportCardList(search);
    // 対象の日付を取得
    const tmpViewDate = getWeekDatesWithDays(
      search.project.load_date_from,
      search.project.load_date_to
    );

    const transports = res.data; // 検索条件に一致するもの

    // データを格納
    setDateTransports((prev) => {
      let temp: Record<string, tTransportCardList> = {};
      Object.keys(tmpViewDate).forEach((date) => {
        // 1日分のデータを取得
        const targets: tTransport[] = transports.filter(
          (transport: tTransport) => {
            if (!transport.project) {
              // プロジェクト情報がない場合は何も返さない
              return false;
            }

            if (!isSameDate(transport.project.load_date, date)) {
              return false;
            }

            return true;
          }
        );

        const dateTransportCard: tTransportCardList = targets
          .filter((transport: tTransport) => transport.project) // プロジェクトが存在するものだけフィルタリング
          .map((transport: tTransport) => {
            return {
              id: transport.id,
              pj_id: transport.pj_id,
              no: transport.no,
              company_name: getTorisakiName(transport.project),
              load_date: transport.project?.load_date || "",
              load_id: transport.project?.load_address?.id || 0,
              load_name: getLoadName(transport.project),
              unload_date: transport.project?.unload_date || "",
              unload_id: transport.project?.unload_address?.id || 0,
              unload_name: getUnloadName(transport.project),
              status: transport.status,
              kind: transport.project?.project_kind?.id || 0,
              kind_name: transport.project?.project_kind?.name || "",
            };
          });

        temp[date] = dateTransportCard;
      });

      return {
        ...temp,
      };
    });

    setViewDate(tmpViewDate);
  };

  useEffect(() => {
    searchProject();
  }, []);

  useEffect(() => {
    if (!projectKinds) {
      return;
    }

    const newPK = [...projectKinds, initialProjectKind];
    const tmpCheck: Record<number, boolean> = {};
    newPK.map((kind) => {
      tmpCheck[Number(kind.id)] = true;
    });

    setViewPK(newPK);
    setCheckedKind(tmpCheck);
  }, [projectKinds]);

  if (loading || !projectKinds) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Modal
        title={"検索"}
        content={<ModalSearch search={searchPJ} setSearch={setSearchPJ} />}
        actions={
          <>
            <Button
              onClick={() => {
                searchProject();
                setOpenSearch(false);
              }}
            >
              検索
            </Button>
            <Button onClick={() => setOpenSearch(false)}>閉じる</Button>

            <LinkPrimaryButton label="TOPへ" href={"/"} />
          </>
        }
        open={openSearch}
      />
      <div className="row head">
        <div className="cell day">
          <Button onClick={() => setOpenSearch(true)}>検索</Button>
        </div>
        {viewPK.map((projectKind, index) => (
          <div
            key={`schedule-rowhead-${projectKind.id}`}
            className={`cell kind ${
              checkedKind[projectKind.id] ? "active" : ""
            }`}
          >
            <Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    onChange={(e) =>
                      setCheckedKind({
                        ...checkedKind,
                        [projectKind.id]: e.target.checked,
                      })
                    }
                    value={checkedKind[Number(projectKind.id)]}
                  />
                }
                label={
                  checkedKind[Number(projectKind.id)] ? projectKind.name : ""
                }
              />
            </Typography>
          </div>
        ))}
      </div>

      {Object.keys(dateTransports).map((date, dIndex) => (
        <div
          key={`schedule-column-${date}`}
          className={`row data ${viewDate[date].weekday}`}
          style={{ minHeight: "75px" }}
        >
          <div className="cell day">
            <Link href={`/project/input?date=${date}`}>
              <Typography>{`${date}(${getCharWeekday(date)})`}</Typography>
            </Link>
          </div>

          {viewPK?.map((kind, index) => {
            const categoryTransports = dateTransports[date].filter(
              (project) => {
                return project.kind === kind.id;
              }
            );

            return (
              <div
                key={`schedule-column-${date}-${kind.id}`}
                className={`cell kind ${
                  checkedKind[Number(kind.id)] ? "active" : ""
                } ${index % 2 === 0 ? "even" : "odd"}`}
                onDoubleClick={() => {
                  navigate(`/project/input?date=${date}&kind=${kind.id}`);
                }}
              >
                {categoryTransports.map((transport, i) => {
                  return (
                    <TransportCard
                      key={`schedule-column-${date}-${kind.id}-${i}`}
                      type={"transport-selectOperation"}
                      transportCard={transport}
                      flgOmit={!checkedKind[Number(kind.id)]}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      ))}
    </>
  );
}

type tViewDate = Record<string, typeHash>;

function getWeekDatesWithDays(startDate: string, endDate: string): tViewDate {
  const daysOfWeek: string[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let weekDates: tViewDate = {};

  // startDateからendDateまでの日付を取得
  let currentDate = new Date(startDate);
  const finalDate = new Date(endDate);

  while (currentDate <= finalDate) {
    // 日付をキーに曜日を値とするオブジェクトを作成
    const dateKey: string = currentDate.toISOString().split("T")[0];
    const dayOfWeek: string = daysOfWeek[currentDate.getDay()];

    weekDates[dateKey] = { weekday: dayOfWeek };

    // １日加算
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return weekDates;
}

function ModalSearch({
  search,
  setSearch,
}: {
  search: tProjectSearchCustom;
  setSearch: React.Dispatch<React.SetStateAction<tProjectSearchCustom>>;
}) {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <RangeLoadDatetime values={search} setValues={setSearch} />
      </Grid>
      <ClientCompany
        values={search}
        setValues={setSearch}
        size={{ xs: 12, lg: 4 }}
      />
      {/*
      <Status values={search} setValues={setSearch} size={{ xs: 12, lg: 4 }} />
      */}
      {/*
      <Status values={search} setValues={setSearch} size={{ xs: 12, lg: 4 }} />
      */}
    </Grid>
  );
}
