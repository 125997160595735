import React, { createContext, useContext, useState, ReactNode } from "react";
import { tTransportCard, tTransportCardList } from "types/transport";

// グローバル状態のContextを作成
const SelectionContext = createContext<{
  outbound: tTransportCardList;
  inbound: tTransportCardList;
  addSelection: ({
    transportCard,
    type,
  }: {
    transportCard: tTransportCard;
    type: string;
  }) => void;
  removeSelection: ({
    transportCard,
    type,
  }: {
    transportCard: tTransportCard;
    type: string;
  }) => void;
} | null>(null);

// グローバル状態を管理するProviderコンポーネント
export const SelectionProvider = ({ children }: { children: ReactNode }) => {
  const [outbound, setOutbound] = useState<tTransportCardList>([]);
  const [inbound, setInbound] = useState<tTransportCardList>([]);

  const addSelection = ({
    transportCard,
    type,
  }: {
    transportCard: tTransportCard;
    type: string;
  }) => {
    if (type === "outbound") {
      setOutbound((prev) => [...prev, transportCard]);
    } else {
      setInbound((prev) => [...prev, transportCard]);
    }
  };

  const removeSelection = ({
    transportCard,
    type,
  }: {
    transportCard: tTransportCard;
    type: string;
  }) => {
    if (type === "outbound") {
      setOutbound((prev) => prev.filter((t) => t !== transportCard));
    } else {
      setInbound((prev) => prev.filter((t) => t !== transportCard));
    }
  };

  return (
    <SelectionContext.Provider
      value={{ outbound, inbound, addSelection, removeSelection }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

// グローバル状態を利用するためのカスタムフック
export const useSelection = () => {
  const context = useContext(SelectionContext);
  if (!context) {
    throw new Error("useSelection must be used within a SelectionProvider");
  }
  return context;
};
