import { createContext, useState, useEffect, ReactNode } from "react";
import {
  tProjectKind,
  tCompany,
  tLuggage,
  //tCarModel,
  tAddress,
  tRole,
  tVehicle,
  tVehicleType,
  tUser,
} from "types/mst";
import {
  getProjectKindList,
  getCompanyList,
  getLuggageList,
  //getCarModel,
  getAddressList,
  getRoleList,
  getVehicleList,
  getVehicleTypeList,
  getUserList,
} from "functions/api/mst";

interface MstDataContextProps {
  loading: boolean;
  users: tUser[] | null;
  loginUser: tUser | any;
  projectKinds: tProjectKind[] | null;
  companies: tCompany[] | null;
  luggages: tLuggage[] | null;
  //carModels: tCarModel[] | null;
  addresses: tAddress[] | null;
  roles: tRole[] | null;
  vehicles: tVehicle[] | null;
  vehicleTypes: tVehicleType[] | null;
  fetchData: () => Promise<void>;
}

// デフォルト値を定義
const defaultValue: MstDataContextProps = {
  loading: false,
  users: null,
  loginUser: null,
  projectKinds: null,
  companies: null,
  luggages: null,
  //carModels: null,
  addresses: null,
  roles: null,
  vehicles: null,
  vehicleTypes: null,
  fetchData: async () => {},
};

export const MstDataContext = createContext<MstDataContextProps>(defaultValue);

interface MstDataProviderProps {
  children: ReactNode;
}

export const MstDataProvider = ({ children }: MstDataProviderProps) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [loginUser, setLoginUser] = useState(null);
  const [projectKinds, setProjectKinds] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [luggages, setLuggages] = useState(null);
  //const [carModels, setCarModels] = useState(null);
  const [addresses, setAddresses] = useState(null);
  const [roles, setRoles] = useState(null);
  const [vehicles, setVehicles] = useState(null);
  const [vehicleTypes, setVehicleTypes] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    // csrfトークンを取得しセット
    //await axios.getCsrf();
    try {
      // 並行してデータを取得
      const [
        usersRes,
        rolesRes,
        projectKindsRes,
        companiesRes,
        luggagesRes,
        //carModelsRes,
        vehiclesRes,
        addressesRes,
        vehicleTypesRes,
      ] = await Promise.all([
        getUserList(),
        getRoleList(),
        getProjectKindList(),
        getCompanyList(),
        getLuggageList(),
        //getCarModel(),
        getVehicleList(),
        getAddressList(),
        getVehicleTypeList(),
      ]);

      // データをセット
      setUsers(usersRes.data);
      setRoles(rolesRes.data);
      setProjectKinds(projectKindsRes.data);
      setCompanies(companiesRes.data);
      setLuggages(luggagesRes.data);
      //setCarModels(carModelsRes.data);
      setVehicles(vehiclesRes.data);
      setAddresses(addressesRes.data);
      setVehicleTypes(vehicleTypesRes.data);

      console.log("users", usersRes.data);
      const lu = usersRes.data.find(
        (user: tUser) => user.email === localStorage.getItem("username")
      );
      console.log("loginUser", lu);

      setLoginUser(lu);
    } catch (error) {
      console.error("Failed to fetch common data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MstDataContext.Provider
      value={{
        users,
        loading,
        loginUser,
        projectKinds,
        companies,
        luggages,
        //carModels,
        addresses,
        roles,
        vehicles,
        vehicleTypes,
        fetchData,
      }}
    >
      {children}
    </MstDataContext.Provider>
  );
};
