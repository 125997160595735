const mycompany = {
  name: "株式会社野村貨物",
  postal_code: "797-1104",
  address1: "愛媛県西予市野村町河西1166-6",
  tel: "0894-75-0303",
  fax: "0894-75-0800",
  bank1: {
    name: "伊予銀行",
    branch: "野村支店",
    account_type: "当座",
    account_number: "2001079",
  },
  bank2: {
    name: "愛媛信用金庫",
    branch: "野村支店",
    account_type: "当座",
    account_number: "95",
  },
  bank3: {
    name: "東宇和農業協同組合",
    branch: "野村支店",
    account_type: "普通",
    account_number: "1886124",
  },
};

export default mycompany;
